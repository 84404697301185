module.exports = {
  tHome: 'Crossdressing Blog',
  tAbout: 'About me',
  tCategories: 'Categories',
  tTutorials: 'Tutorials',
  tClothes: 'Clothes',
  tShoes: 'Shoes',
  tLinks: 'Links',
  tLifestyle: 'Lifestyle',
  tMyStory: 'My story',
  tRelativePosts: 'Relative Posts',
  tFollowTwitterDescription: 'You should follow him on Twitter',
  tTags: 'Tags',
  tIndTitle: 'Crossdressing Blog',
  taIndKeywords: [`blog`, `crossdressing`, `crossdresser`, `transvestite`],
  tfIndCountPosts: count => `${count} Posts`,
  tfTagHeader: (totalCount, tag) =>
    `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "${tag}"`,
  t404Title: 'Not Found',
  t404Content: 'You just hit a route that doesn&#39;t exist... the sadness.',
  bio: 'I like to call myself a genderfluid person. I was born male and functioned in that way for a long time. But for some time now, my feminine nature has been making itself strongly known. I am in a happy relationship with the woman of my life, who supports me wholeheartedly. On this blog, I would like to present what life looks like from my perspective, as a crossdresser, a transgender person, or formerly a transvestite. Additionally, I also want to help those who are lost in this world through guides and direct conversation. Write your questions in the blog comments or directly to me via email/Instagram/Facebook. I will try to help as much as possible.',
  tSortByDate: 'Sort posts by date',
  tSortByPopularity: 'Sort posts by popularity',
  tPrevious: 'Previous',
  tNext: 'Next',
};
