const site = {
  pathPrefix: '/my-crossdressing',
  title: 'Blog about a man who sometimes is a woman',
  titlePL: 'Blog o mężczyźnie, który czasami jest kobietą',
  author: 'Sonia Yasme',
  description: 'A blog about crossdressing, written from the perspective of a person who does not fit into rigid gender frameworks and likes to express themselves through clothing.',
  descriptionPL: 'Blog o crossdressingu, pisany z perspektywy osoby, która nie mieści się w sztywnych ramach płci i lubi wyrażać siebie poprzez ubrania.',
  siteUrl: 'https://mycrossdressing.com',
  twitter: 'SYasme',
  medium: 'soniayasme',
  facebook: 'soniayasme',
  instagram: 'soniayasme',
  mail: 'soniayasme@gmail.com',
  disqusShortName: 'mycrossdressing',
  googleTrackingId: '',
  lang: 'en',
  displayTranslations: true,
};

const supportedLanguages = {
  en: 'English',
  pl: 'Polski',
};

module.exports = {
  site,
  supportedLanguages,
};
